import React from 'react';
import Section from '../../components/section';
import Layout from '../../components/layout/layout';
import Button from '../../components/button';

import dash from '../../img/Dash-Screen-for-Website.png';
import logo from '../../img/hexometer-logo.svg';
import impactLogo from '../../img/affiliate/impact-logo.svg';
import portfolioIcon from '../../img/affiliate/portfolio-icon.svg';
import ecommerceIcon from '../../img/affiliate/ecommerce-icon.svg';
import agencyIcon from '../../img/affiliate/agency-icon.svg';
import illustrationHexometer from '../../img/affiliate/illustration-hexometer.png';
import earningPotential from '../../img/affiliate/earning-potential.png';

import './styles.scss';

const Affiliate: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <div className="affiliate-page">
        <section className="seo_home_area" id="seo_home_area">
          <div className="container_home">
            <div className="row_home">
              <div className="col-lg-6 d-flex align-center" style={{verticalAlign: 'middle'}}>
                <div className="prototype_content_home">
                  <h4 className="h4-title f_size_24 t_color3">
                    Become a Hexometer affiliate partner and earn 20% commission on every sale and renewal.
                  </h4>
                  <p className="desc_1">
                    Hexometer has partnered with Impact to power their affiliate program for world-class tracking and
                    convenient payouts.
                  </p>
                  <Button
                    primary
                    className="trial_btn mb-3"
                    onClick={() => window.open('https://app.impact.com/campaign-promo-signup/Hexact.brand')}
                  >
                    Sign up now
                  </Button>
                  <div className="logos">
                    <img src={logo} className="hexometer_logo" alt={'hexometer.com'} />
                    <div className="divider"></div>
                    <img src={impactLogo} className="affiliate_logo" alt="impact.com" />
                  </div>
                  <h4 className="h4-title f_size_24 t_color3">How much can you earn?</h4>
                  <p className="desc_1">
                    Our affiliate program is the perfect way to generate recurring revenue by introducing Hexometer to
                    your audience and local businesses.
                  </p>
                  <p className="desc_1">
                    Hexometer has plans for all business sizes from solopreneurs to established agencies handling
                    hundreds of clients.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 text-center img header-img-block">
                <img
                  src={dash}
                  alt="AI sidekick to protect and grow eCommerce business"
                  style={{maxWidth: '100%', marginRight: '-20px'}}
                />
              </div>
            </div>
          </div>
        </section>
        <Section>
          <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
            <div className="row">
              <div className="col-lg-6 selling-item">
                <div className="img-circle">
                  <img src={portfolioIcon} alt={'portfolio'} />
                </div>
                <h5>Selling to large agencies</h5>
                <p>
                  If you sold just 10 Agency Guru annual plans this month, you could earn a sweet
                  <span className="mark">$2500</span> and every year collect up to <span className="mark">$2500*</span>{' '}
                  in commissions as long as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Agency Guru plans a month and you could earn up to <span className="mark">$30,000</span>{' '}
                  a year after just one year*.
                </p>
                <p>*Based on customers renewing their Agency guru plans on a yearly basis.</p>
              </div>
              <div className="col-lg-6 selling-item">
                <div className="img-circle ">
                  <img src={agencyIcon} alt={'agency'} />
                </div>
                <h5>Selling to smaller agencies</h5>
                <p>
                  If you sold just 10 Agency Standard annual plans this month, you could earn a sweet{' '}
                  <span className="mark">$900</span> and every year collect up to <span className="mark">$900**</span>{' '}
                  in commissions as long as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Agency Standard plans a month and you could earn up to{' '}
                  <span className="mark">$10,800 </span>a year after just one year**.
                </p>
                <p>**Based on customers renewing their Agency standard plans on a yearly basis.</p>
              </div>
              <div className="col-lg-6 selling-item">
                <div className="img-circle">
                  <img src={ecommerceIcon} alt={'exommerce'} />
                </div>
                <h5>Selling to eCommerce businesses</h5>
                <p>
                  If you sold just 10 Guru annual plans this month, you could earn a sweet{' '}
                  <span className="mark">$960</span> and every year collect up to <span className="mark">$960***</span>{' '}
                  in commissions as long as they keep subscribing.
                </p>
                <p>
                  Sell just 10 Guru plans a month and you could earn up to <span className="mark">$11,520</span> a year
                  after just one year***.
                </p>
                <p>***Based on customers renewing their Guru plans on a yearly basis.</p>
              </div>
              <div className="col-lg-6 selling-item selling-img">
                <img src={illustrationHexometer} className="illustration-img" alt="illustration" />
              </div>
            </div>
            <div className="row row-reverse">
              <div className="col-lg-6 d-flex">
                <div className="m-auto text-center text-lg-left">
                  <h5 className="text-center text-lg-left">Tap into unlimited earning potential</h5>
                  <p className="text-center text-lg-left">
                    Earn commissions on all purchases made by new clients you refer across Hexomatic, Hexowatch,
                    Hexometer, Hexospark, and premium credit subscriptions.
                  </p>
                  <Button
                    primary
                    className="trial_btn mb-3"
                    onClick={() => window.open('https://app.impact.com/campaign-promo-signup/Hexact.brand')}
                  >
                    Sign up now
                  </Button>
                </div>
              </div>
              <div className="col-lg-6 d-flex">
                <img
                  src={earningPotential}
                  style={{maxWidth: '450px', width: '100%', margin: 'auto'}}
                  alt="Earning Potential"
                />
              </div>
            </div>
          </div>
          <div className='mt-5 w-100' style={{textAlign: 'center', fontSize: '20px', fontWeight: 500}}>Try the easiest <a style={{fontSize: '20px'}} href='https://searchscraper.io/?utm_source=hexometer&utm_medium=affiliate-cta' className='clickable-text' target="_blank">Google web scraper</a>.</div>
        </Section>
      </div>
    </Layout>
  );
};

export default Affiliate;
